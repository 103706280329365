import { ProductSearch, ProductSearchDataType } from '../services/productSearch';
import { FiltersSelectValuesType, HandleFiltersType } from '../types/components/productSearch';

const pounds = (n: number) => n;
const sizeUnitsHelperFns = {
    // ? Liquid * to gallon [gal]
    ml: (n: number) => (n * 3.7854) / 1000,
    'fl oz': (n: number) => n * 128,
    cup: (n: number) => n * 16,
    pt: (n: number) => n * 8,
    qt: (n: number) => n * 4,
    l: (n: number) => n * 3.7854,
    gal: (n: number) => n,
    // ? Weight * to pound [lb/lbs]
    g: (n: number) => n * 453.59237,
    oz: (n: number) => n * 16,
    kg: (n: number) => n * 0.45359237,
    lbs: pounds,
    lb: pounds,
};

const sizeSorting = (a: string | null, b: string | null, type?: 'asc' | 'desc') => {
    let aValue = 0;
    let bValue = 0;
    if (typeof a === 'string' && a !== '') {
        const aIndex = a.indexOf(' ');
        const aKey = a.substring(aIndex + 1, a.length);
        aValue = sizeUnitsHelperFns?.[aKey]?.(Number(a.substring(0, aIndex)));
    }
    if (typeof b === 'string' && b !== '') {
        const bIndex = b.indexOf(' ');
        const bKey = b.substring(bIndex + 1, b.length);
        bValue = sizeUnitsHelperFns?.[bKey]?.(Number(b.substring(0, bIndex)));
    }
    if (type === 'desc') return aValue - bValue;
    return bValue - aValue;
};

const formateStringArray = (arr?: string[]) =>
    arr?.length > 0 ? arr?.sort((a, b) => a.localeCompare(b)) : [];

const formateSizeArray = (arr?: Array<number | string>) =>
    arr?.map((item: number) =>
        // ? @mantine Only deals with strings
        item === null ? ' ' : String(item),
    ) || [];

const formateNumberStringsArray = (arr?: Array<string>) =>
    arr
        ?.sort((a, b) => Number(a.split(/[^0-9]/g)[0]) - Number(b.split(/[^0-9]/g)[0]))
        .map(
            (item: string) =>
                // ? @mantine Only deals with strings
                item,
        ) || [];

const getSearchFiltersData = ({
    defaultSearchData,
    defaultSearchDataLoading,
    filtersSelectValues,
    firstSearchData,
    secondSearchData,
}: {
    defaultSearchData: ProductSearch;
    defaultSearchDataLoading: boolean;
    filtersSelectValues: FiltersSelectValuesType;
    firstSearchData: ProductSearch;
    secondSearchData: ProductSearch;
}): ProductSearchDataType | undefined => {
    if (!defaultSearchData?.productSearchData || defaultSearchDataLoading) return undefined;
    if (filtersSelectValues.all.requestKeys.length === 0)
        return defaultSearchData?.productSearchData;
    const data: ProductSearchDataType = {
        ...defaultSearchData?.productSearchData,
    };
    const secondKeysSize = filtersSelectValues.second.requestKeys.length;
    // ? This part is where we use the ids to assign Selects Data
    if (
        (!filtersSelectValues.all.params?.brand && secondKeysSize === 1) ||
        filtersSelectValues.all.params?.brand?.id === 1
    ) {
        data.brands = firstSearchData?.productSearchData?.brands;
    } else if (
        !filtersSelectValues.all.params?.brand ||
        filtersSelectValues.all.params?.brand?.id === 2
    ) {
        data.brands = secondSearchData?.productSearchData?.brands;
    }
    if (
        (!filtersSelectValues.all.params?.pack && secondKeysSize === 1) ||
        filtersSelectValues.all.params?.pack?.id === 1
    ) {
        data.packs = firstSearchData?.productSearchData?.packs;
    } else if (
        !filtersSelectValues.all.params?.pack ||
        filtersSelectValues.all.params?.pack?.id === 2
    ) {
        data.packs = secondSearchData?.productSearchData?.packs;
    }
    if (
        (!filtersSelectValues.all.params?.size && secondKeysSize === 1) ||
        filtersSelectValues.all.params?.size?.id === 1
    ) {
        data.sizes = firstSearchData?.productSearchData?.sizes;
    } else if (
        !filtersSelectValues.all.params?.size ||
        filtersSelectValues.all.params?.size?.id === 2
    ) {
        data.sizes = secondSearchData?.productSearchData?.sizes;
    }
    return { ...data };
};

const defaultFiltersSelectValues: FiltersSelectValuesType = {
    first: { requestKeys: [], params: {}, requestParams: {} },
    second: { requestKeys: [], params: {}, requestParams: {} },
    all: { requestKeys: [], params: {}, requestParams: {} },
};

const handleFilters: HandleFiltersType =
    // ? Mapping is when we map onMount with query val
    ({ name, obj, reset }, mapping?: FiltersSelectValuesType) => {
        // ? this is to deal with ID of 0
        if (reset && !name) {
            return { ...defaultFiltersSelectValues };
        }

        let newFiltersSelectValues: FiltersSelectValuesType =
            reset || obj.id === 0
                ? // ? Don't add default here. most probably because the useCallback functionality it memoized this part.
                  // ? so add to it will be there until we flush/restart the page. that's why it has to be static
                  {
                      first: { requestKeys: [], params: {}, requestParams: {} },
                      second: { requestKeys: [], params: {}, requestParams: {} },
                      all: { requestKeys: [], params: {}, requestParams: {} },
                  }
                : mapping;
        if (typeof obj.value === 'string') {
            newFiltersSelectValues.all.requestKeys[obj.id] = obj.value;
            newFiltersSelectValues.all.requestParams[name] = obj.value;
            newFiltersSelectValues.all.params[name] = obj;
            if (obj.id === 0) {
                newFiltersSelectValues.first.requestKeys[obj.id] = obj.value;
                newFiltersSelectValues.first.requestParams[name] = obj.value;
                newFiltersSelectValues.first.params[name] = obj;
            }
            if (obj.id <= 1) {
                newFiltersSelectValues.second.requestKeys[obj.id] = obj.value;
                newFiltersSelectValues.second.requestParams[name] = obj.value;
                newFiltersSelectValues.second.params[name] = obj;
            }
            return newFiltersSelectValues;
        }

        // ? Dealing with null and undefined
        if (obj.id === 1) {
            newFiltersSelectValues = {
                ...newFiltersSelectValues,
                all: {
                    ...newFiltersSelectValues.all,
                    params: Object.entries(newFiltersSelectValues.all.params || {}).reduce(
                        (acc, [key, value]) =>
                            value?.id && value.id === 1
                                ? { ...acc, [key]: { ...value, id: 1 } }
                                : { ...acc, [key]: value },
                        {} as FiltersSelectValuesType['all']['params'],
                    ),
                },
                second: {
                    ...newFiltersSelectValues.second,
                    params: Object.entries(newFiltersSelectValues.second.params || {}).reduce(
                        (acc, [key, value]) =>
                            value?.id && value.id === 2
                                ? { ...acc, [key]: { ...value, id: 1 } }
                                : { ...acc, [key]: value },
                        {} as FiltersSelectValuesType['all']['params'],
                    ),
                },
            };

            const nameOfIndexZeroSelectValue = Object.entries(
                newFiltersSelectValues.all.params || {},
            ).filter(select => select[1]?.id && select[1].id === 0)[0][0];
            newFiltersSelectValues.all.requestKeys.splice(
                obj.id,
                newFiltersSelectValues.all.requestKeys.length - 1,
            );

            newFiltersSelectValues.all.requestParams = {
                [nameOfIndexZeroSelectValue]:
                    newFiltersSelectValues.all.requestParams[nameOfIndexZeroSelectValue],
            };
            newFiltersSelectValues.all.params = {
                [nameOfIndexZeroSelectValue]:
                    newFiltersSelectValues.all.params[nameOfIndexZeroSelectValue],
            };

            newFiltersSelectValues.second.requestKeys.splice(
                obj.id,
                newFiltersSelectValues.second.requestKeys.length - 1,
            );

            newFiltersSelectValues.second.requestParams = {
                [nameOfIndexZeroSelectValue]:
                    newFiltersSelectValues.second.requestParams[nameOfIndexZeroSelectValue],
            };
            newFiltersSelectValues.second.params = {
                [nameOfIndexZeroSelectValue]:
                    newFiltersSelectValues.second.params[nameOfIndexZeroSelectValue],
            };
            return newFiltersSelectValues;
        }

        // ? id === 2 handling --- Note: First object is not used after passing 1
        newFiltersSelectValues.all.requestKeys.splice(obj.id, 1);
        newFiltersSelectValues.all.requestParams[name] = undefined;
        newFiltersSelectValues.all.params[name] = undefined;
        newFiltersSelectValues.second.requestKeys.splice(obj.id, 1);
        newFiltersSelectValues.second.requestParams[name] = undefined;
        newFiltersSelectValues.second.params[name] = undefined;

        return newFiltersSelectValues;
    };

const getMetricsFullWord = (str: string) => {
    if (/avg/g.test(str)) {
        return 'Average';
    }
    if (/p5/g.test(str)) {
        return 'p5';
    }
    if (/p95/g.test(str)) {
        return 'p95';
    }
    if (/min/g.test(str)) {
        return 'Minimum';
    }
    if (/max/g.test(str)) {
        return 'Maximum';
    }
    if (/pct/g.test(str)) {
        return 'Percentage';
    }
    if (/price/g.test(str)) {
        return 'Price';
    }
    return str;
};

const capFirstLetterAndLowerOthers = (str = '') =>
    str
        .toLowerCase()
        .split('')
        .map((letter, i) => (i === 0 ? letter.toUpperCase() : letter))
        .join('');

export const capFirstLetterAndLowerOthersAllWords = (str = '') =>
    str?.split(' ').map(capFirstLetterAndLowerOthers).join(' ');

export {
    formateStringArray,
    formateSizeArray,
    formateNumberStringsArray,
    getSearchFiltersData,
    defaultFiltersSelectValues,
    handleFilters,
    sizeSorting,
    getMetricsFullWord,
    capFirstLetterAndLowerOthers,
};
